<template>
<v-app id="collectWatchPage" class="collectWatchPage" >
      <section>
      <v-layout justify-center class="my-5">
        <v-row>
          <h1 class="display-1 iceBlue mx-12">{{content.title}}</h1>
        </v-row>
      </v-layout>
    </section>
  <!-- <div> -->
    <!-- <div id="collectWatchId"> -->
      <!-- <v-app light> -->
        <section class="lighter-section">
          <v-layout justify-center class="my-12" v-scrollanimationx>
            <h1 class="display-1 iceBlue mx-12">{{content.collectFlow}}</h1>
          </v-layout>
          <v-layout justify-center>
            <center>
              <!-- <img
                class="image800Width"
                src="https://firebasestorage.googleapis.com/v0/b/wewatch-website.appspot.com/o/image%2FstepsImg.png?alt=media&token=cc7cc123-c159-4a91-a09f-89222aceab68"
              />-->
              <img
                class="maxWidth959"
                src="https://firebasestorage.googleapis.com/v0/b/wewatch-website.appspot.com/o/image%2F4_step_logo.png?alt=media&token=c2f4c6c9-c107-4e2c-97b8-ba894b8a9e11"
              />
            </center>
          </v-layout>
          <v-layout>
            <v-row class="justify-center">
              <v-btn class="bigbigBtn" color="green lighten-3" :href="socials.link">
                <div style="text-align:center;" class="title">
                  <v-row align="center" justify="center">
                    <v-col cols="3">
                      <v-btn class="pl2 pr2 red--text" fab icon>
                        <v-icon :color="socials.color" size="45">{{ socials.icon }}</v-icon>
                      </v-btn>
                    </v-col>
                    <v-col align="center">
                      <v-row justify="center">{{ content.contactUs }}</v-row>
                      <v-row justify="center">
                        <div class="headline">{{ content.contactUsText }}</div>
                      </v-row>
                    </v-col>
                  </v-row>
                </div>
              </v-btn>

              <!-- <v-col cols="10" class="py-0">

                </div>
              </v-col>-->
            </v-row>
          </v-layout>
          <v-layout>
            <v-row class="justify-center my-12">
              <v-col cols="8">
                <v-timeline align-top :dense="$vuetify.breakpoint.smAndDown">
                  <v-timeline-item
                    v-for="(item, i) in items"
                    :key="i"
                    :color="item.color"
                    :icon="item.icon"
                    fill-dot
                  >
                    <v-card :color="item.color" dark>
                      <v-card-title class="title">{{item.title}}</v-card-title>
                      <v-card-text class="white subtitle-1">
                        <div class="black--text" v-html="item.content"></div>
                      </v-card-text>
                    </v-card>
                  </v-timeline-item>
                </v-timeline>
              </v-col>
            </v-row>
          </v-layout>
        </section>

        <section>
          <v-layout justify-center class="my-12">
            <v-row align="center" class="maxWidth100Per" >
              <v-col cols="12" v-scrollanimationx>
                <h1 class="display-1 iceBlue">{{content.collectBand}}</h1>
              </v-col>
              <v-col
                cols="3"
                class="ma-0 pa-0"
                align="center"
                v-for="(brand, i) in brandItems"
                :key="i"
              >
                <v-img class="inheritWidth" contain max-width :src="brand.src" :alt="brand.alt" />
              </v-col>
            </v-row>
          </v-layout>
          <!-- <v-layout justify-center>
            <p>{{content.collectBandNames}}</p>
          </v-layout>-->
        </section>

        <section class="p10 lighter-section">
          <v-layout row wrap justify-center class="my-12" v-scrollanimationx>
            <h1 class="display-1 iceBlue mx-12">{{content.tip}}</h1>
          </v-layout>
          <v-layout justify-center class="my-12">
            <div class="textbox-bigger text-left">
              <v-list>
                <v-list-item
                  v-for="(oneTip, i) in this.content.tips"
                  :key="i"
                  @click=" tempVar = 1"
                >
                  <v-list-item-content>
                    <p class="title" v-text="oneTip"></p>
                    <!-- <v-list-item-title class="title" v-text="oneTip"></v-list-item-title> -->
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </div>
            <!-- <div class="textbox-bigger">
              <p>來到 WeWatch 的顧客，我們都會以親切的笑容熱誠招待。</p>
              <p>無論你只是偶然想知道愛錶的價錢，抑或您真的想放錶即時現金交收，我們都重視你提出的查詢或要求。</p>
              <p>whatsapp 給我們，只要資料齊全，不論新錶、舊錶、古董錶、淨錶、壞錶，我們都定當盡快為你報價，為您尋求最妥善的處理方案，放錶、換錶從此無煩惱。</p>
            </div>-->
          </v-layout>
        </section>
      <!-- </v-app> -->
    <!-- </div> -->
  <!-- </div> -->
</v-app>
</template>

<script>
//   new Vue({
//    el: '#app',
//    vuetify: new Vuetify(),
//    data () {
//      return {
//        title: 'Your Logo'
//      }
//    }
//  })

// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'collectWatchPage',
  data () {
    return {
      content: {},
      title: 'Your Logo',
      brandItems: [
        {
          src:
            'https://firebasestorage.googleapis.com/v0/b/wewatch-website.appspot.com/o/brandIcon%2Fbrand_Rolex.webp?alt=media&token=2a9fd69b-b262-4b0c-8a01-e66dd928a3a3',
          alt: 'Rolex'
        },
        {
          src:
            'https://firebasestorage.googleapis.com/v0/b/wewatch-website.appspot.com/o/brandIcon%2Fbrand_patex_philippe.jpg?alt=media&token=847f53a6-28fd-4b7a-b172-b73a7ab1e68b',
          alt: 'Patek Philippe'
        },
        {
          src:
            'https://firebasestorage.googleapis.com/v0/b/wewatch-website.appspot.com/o/brandIcon%2Fbrand_audemars_piguet.jpg?alt=media&token=162c5e79-2271-4c46-a200-26d57d55c96e',
          alt: 'Audemars Piguet'
        },
        {
          src:
            'https://firebasestorage.googleapis.com/v0/b/wewatch-website.appspot.com/o/brandIcon%2Fbrand_tudor.jpg?alt=media&token=f42105e1-f69d-49ee-8f09-4e692f8b6133',
          alt: 'Tudor'
        },
        {
          src:
            'https://firebasestorage.googleapis.com/v0/b/wewatch-website.appspot.com/o/brandIcon%2Fbrand_OMEGA.png?alt=media&token=aa4c2ef6-4725-4a54-8a72-535d65335d01',
          alt: 'OMEGA'
        },
        {
          src:
            'https://firebasestorage.googleapis.com/v0/b/wewatch-website.appspot.com/o/brandIcon%2Fbrand_officine_panerai.jpg?alt=media&token=aabfde40-dbcc-490c-b2d7-eb66731ca097',
          alt: 'Officine Panerai'
        },
        {
          src:
            'https://firebasestorage.googleapis.com/v0/b/wewatch-website.appspot.com/o/brandIcon%2Fbrand_IWC.png?alt=media&token=74cc601d-d772-4eb5-884d-da27bf494c18',
          alt: 'IWC'
        },
        {
          src:
            'https://firebasestorage.googleapis.com/v0/b/wewatch-website.appspot.com/o/brandIcon%2Fbrand_Cartier.webp?alt=media&token=6e985869-47f5-4f1d-af2f-867b369c1e4a',
          alt: 'Cartier'
        }
      ],
      items: [],
      socials: {
        icon: 'fab fa-whatsapp fa-10x',
        color: '#FFFFFF',
        link: 'https://wa.me/85268819431/?text=' + encodeURI('我想放售手錶!')
      }
    }
  },
  mounted () {
    if (localStorage.language !== 'en') {
      this.content = {
        title: '名錶回收',
        collectBand: '收購品牌',
        collectFlow: '網上報價流程',
        // promise: '服務承諾',
        tip: '放售貼士',
        tips: [
          '1. 若購買時之所有隨錶附件，保證書及原裝盒越完齊全，價值就越高。如缺少部份亦可免費鑑定。',
          '2. 帶同實物到店鑑定，報價準確度更高。',
          '3. 交換手錶價錢更優惠。',
          '4. 若確定不再使用,建議盡早出售避免手錶狀態轉壞',
          '5. 同時放售商品數量越多,回收報價越高'
        ],
        collectBandNames: '勞力士 Rolex',
        contactUs: '用WhatsApp立即',
        contactUsText: '聯繫我們'
      }
      this.items = [
        {
          color: 'red lighten-2',
          icon: 'mdi-star',
          title: '拍攝',
          content:
            '拍攝手錶實物：手錶正背面，四側及錶帶連扣，<br>證書(包括型號及年份)，附件和禮盒'
        },
        {
          color: 'purple darken-1',
          icon: 'mdi-book-variant',
          title: '傳送',
          content: '將相片連同期望賣價WhatsApp到我們<br>官方電話 +852 6881 9431'
        },
        {
          color: 'green lighten-1',
          icon: 'mdi-airballoon',
          title: '報價',
          content: '收到相片後，Wewatch將給予初步報價'
        },
        {
          color: 'indigo',
          icon: 'mdi-buffer',
          title: '交收',
          content:
            '可預約到門市驗貨確認並現金交收<br>(地址: 旺角廣華街仁安大廈地鋪8號A舖)'
        }
      ]
    } else {
      this.content = {
        title: 'Sell/Trade in',
        collectBand: 'Trade In Brands',
        collectFlow: 'Processes for Trading in Watches',
        // promise: 'Our Visions',
        tip: 'Trading Tips',
        tips: [
          '1. 若購買時之所有隨錶附件，保證書及原裝盒越完齊全，價值就越高。如缺少部份亦可免費鑑定。',
          '2. 帶同實物到店鑑定，報價準確度更高。',
          '3. 交換手錶價錢更優惠。',
          '4. 若確定不再使用,建議盡早出售避免手錶狀態轉壞',
          '5. 同時放售商品數量越多,回收報價越高'
        ],
        collectBandNames: 'Rolex',
        contactUs: 'Contact us on',
        contactUsText: 'WhatsApp'
      }
      this.items = [
        {
          color: 'red lighten-2',
          icon: 'mdi-star',
          title: 'Photo',
          content:
            "Take photos for the watch including front, back, side, watch's certificate, watch's package"
        },
        {
          color: 'purple darken-1',
          icon: 'mdi-book-variant',
          title: 'Send',
          content: 'Send these photos on WhatsApp (+852 6881 9431)'
        },
        {
          color: 'green lighten-1',
          icon: 'mdi-airballoon',
          title: 'Quote Price',
          content: 'After receiving photos, we will provide an estimated price'
        },
        {
          color: 'indigo',
          icon: 'mdi-buffer',
          title: 'Make deal',
          content:
            'If you feel good about the estimate price, we can make a cash transaction in our shop'
        }
      ]
    }
  }
}
</script>

<style scoped>
/* h1 {
  color: white;
} */

p {
  color: white;
}

v-img {
  height: 50%;
}

ol {
  color: white;
}

li {
  color: white;
}

body {
  color: white;
}

.textbox {
  width: 400px;
}

.textbox-bigger {
  width: 80%;
}
.bigbigBtn {
  min-height: 6rem;
  width: 300px;
}
</style>
